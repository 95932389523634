﻿
.tools-portal-content_wrap {
    max-width: 1300px;
    margin: $space-300 auto 125px auto;
    padding: 0 $space;
}
.tp-content-header {
    text-align: center;
    margin-bottom: $space;
    font-weight: 700;
    font-size: .875rem;
    color: $dark-gray-text;
}
.tp-content-row {
    display: flex;
    justify-content: center;
}
.tp-content {
    width: 100%;
    max-width: 330px;
    margin: 0 $space;
    border-radius: 4px;

    a {
        padding: $space-200;
        border: 3px solid #D8E5EE;
        border-top: none;
        display: block;
        box-shadow: 0px 7px 9px #042E5C19;
        height: calc(100% - 4rem);
        text-decoration: none;
    }

    &.sitemanager {
        border-top: 8px solid $yellow;

        &:hover {
            a {
                border-color: $yellow;
            }
        }
    }

    &.sitebuilder {
        border-top: 8px solid $red;

        &:hover {
            a {
                border-color: $red;
            }
        }
    }

    &.productbuilder {
        border-top: 8px solid $green;

        &:hover {
            a {
                border-color: $green;
            }
        }
    }

    &.zipline {
        border-top: 8px solid $blue;

        &:hover {
            a {
                border-color: $blue;
            }
        }
    }

    &.composer {
        border-top: 8px solid $navy;

        &:hover {
            a {
                border-color: $navy;
            }
        }
    }

    &.analytics {
        border-top: 8px solid $gray;

        &:hover {
            a {
                border-color: $gray;
            }
        }
    }

    span {
        display: block;

        &.logo-wrap {
            display: flex;
            justify-content: space-between;
            margin-bottom: $space-200;

            i {
                font-size: $space-125;
                color: $icon-gray;
            }
        }
    }
}

.tp-content-text {
    color: #505355;

    > span {
        margin-top: $space;
        display: flex;
        align-items: end;
        font-size: .875rem;

        i {
            margin-right: $space;
            font-size: $space-125;
            color: $icon-gray;
        }

        &:first-of-type {
            margin-top: 0;
        }
    }
}

.cmr-content_wrap {
    margin-top: $space-400;
}
