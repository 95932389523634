/* Sticky footer styles
-------------------------------------------------- */
html {
    position: relative;
    min-height: 100%;
}

body {
    /* Margin bottom by footer height */
    margin: 0;
    margin-bottom: 60px;
}

.footer {
    position: absolute;
    bottom: 0;
    width: calc(100% - 2rem);
    left: 0;
    white-space: nowrap;
    line-height: 60px; /* Vertically center the text there */
    background: $dark-navy;
    padding: 0 1rem;

    * {
        color: $gray-text;
    }
}

.login.mgh .footer {
    display: none;
}
