@charset "UTF-8";
html * {
  font-family: "Source Sans Pro", sans-serif;
}

.tools-portal_header {
  display: flex;
  align-items: center;
  background: transparent linear-gradient(241deg, #189AB9 0%, #006CA0 14%, #004375 89%, #092757 100%) 0% 0% no-repeat padding-box;
  height: 68px;
  padding: 0 2rem;
}

.tools-portal-nav {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.tools-portal-nav li {
  margin-left: 2rem;
}
.tools-portal-nav li a {
  text-decoration: none;
}
.tools-portal-nav li a:hover {
  text-decoration: underline;
}
.tools-portal-nav li > a {
  color: #feffff;
}

.user-info a {
  text-decoration: none;
}
.user-info a:hover {
  text-decoration: underline;
}
.user-info > a {
  color: #feffff;
}

li.with-dropdown {
  position: relative;
}

.dropdown-trigger {
  white-space: nowrap;
}

.dropdown-trigger:after {
  font-family: "feather" !important;
  content: "";
  margin-left: 0.5rem;
  display: inline-block;
}

.dropdown {
  display: none;
  list-style-type: none;
  position: absolute;
  top: 44px;
  border: 1px solid #dbdbdb;
  padding: 1rem;
  background: #feffff;
  width: 200px;
  box-shadow: 0 7px 9px #042e5c19;
}
.dropdown li {
  margin-left: 0;
}
.dropdown li a {
  color: #505355;
}

.user-info {
  position: relative;
}
.user-info .dropdown {
  right: 0;
  top: 28px;
}
.user-info .dropdown-trigger {
  display: flex;
  align-items: center;
}
.user-info .dropdown-trigger:before {
  font-family: "feather" !important;
  content: "";
  margin-right: 0.5rem;
  display: inline-block;
}

html.login {
  background: transparent linear-gradient(241deg, #189AB9 0%, #006CA0 14%, #004375 89%, #092757 100%) 0% 0% no-repeat padding-box;
}
html.login .login-wrap .sc-logo {
  text-align: center;
}
html.login .login-wrap .sc-logo img {
  width: 275px;
}
html.login .login-wrap .login-text {
  font-size: 1.5rem;
  color: #feffff;
  text-align: center;
  margin-bottom: 1.5rem;
}
html.login .login-wrap.oauth.score {
  margin: 0 auto;
  padding: 2rem 0;
  height: auto;
  background-image: linear-gradient(to top, #000a39, #0d1f74 95%);
}
html.login .login-wrap.oauth.score .sc-logo img {
  width: auto;
  max-width: 300px;
  max-height: 50px;
}
html.login .login-wrap.oauth.score fieldset {
  padding: 2rem;
  width: auto;
  min-width: calc(300px - 2 * 2rem);
  max-width: 450px;
}

.alert.alert-danger {
  border: 1px solid #E1382C;
  padding: 1rem;
  margin-bottom: 1rem;
}
.alert.alert-danger ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

fieldset {
  background: #feffff;
  border: 1px solid #D8E5EE;
  width: 450px;
  margin: 0 auto;
  padding: 2rem;
  border-radius: 4px;
}
fieldset .form-group .instructions {
  margin: 0 0 1rem 0;
}
fieldset .form-group label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
fieldset .form-group input[type=text], fieldset .form-group input[type=password], fieldset .form-group input[type=email] {
  width: 100%;
  height: 40px;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  margin-bottom: 1.5rem;
  padding: 0 0.5rem;
  box-sizing: border-box;
}
fieldset .form-group.checkbox-row {
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
}
fieldset .form-group.checkbox-row label {
  font-weight: 600;
  margin: 0 0.5rem 0 0;
}
fieldset .form-group.checkbox-row input[type=checkbox] {
  margin: 0.25rem 0;
}
fieldset .form-group.btn-row .btn {
  margin-left: 0.5rem;
}
fieldset .form-group.btn-row .btn:first-of-type {
  margin-left: 0;
}

.btn {
  color: #feffff;
  background-color: #1b6ec2;
  border: 1px solid #1b6ec2;
  border-radius: 4px;
  font-size: 18px;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

html:not(.mgh) .forgot-password-link {
  font-weight: 600;
  margin-left: 1.5rem;
}
html:not(.mgh) .login-wrap {
  height: 420px;
  position: absolute;
  top: 0;
  bottom: 60px;
  left: 0;
  right: 0;
  margin: auto;
}

.return-to-sign-in {
  margin-left: 0.5rem;
  text-decoration: none;
}

html.mgh {
  background: #440B7F;
  font-style: normal;
  font-size: 17px;
  line-height: 22px;
}
html.mgh * {
  font-family: "Roboto", sans-serif;
}
html.mgh .login-wrap {
  height: 600px;
  margin: 10% auto;
}
html.mgh .login-wrap .login-text {
  font-family: "Roboto Serif", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 1;
  text-align: center;
  letter-spacing: -0.408px;
  margin-bottom: 0;
}
@media (max-width: 420px) {
  html.mgh .login-wrap .login-text {
    text-align: left;
    margin-left: 1.5rem;
  }
}
html.mgh .login-wrap .login-text-sub {
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.408px;
  color: #feffff;
  margin: 0.25rem 0 1.5rem 0;
  line-height: 1;
}
@media (max-width: 420px) {
  html.mgh .login-wrap .login-text-sub {
    text-align: left;
    margin-left: 1.5rem;
  }
}
html.mgh .login-wrap fieldset {
  width: 100%;
  padding: 2rem 0;
  box-sizing: border-box;
}
@media (min-width: 421px) {
  html.mgh .login-wrap fieldset {
    border-radius: 36px;
    max-width: 428px;
    max-height: 617px;
  }
}
@media (max-width: 420px) {
  html.mgh .login-wrap fieldset {
    border-top-right-radius: 36px 36px;
  }
}
html.mgh .login-wrap fieldset .form-group {
  margin: 0 auto;
  position: relative;
  max-width: 335px;
  margin-top: 24px;
}
html.mgh .login-wrap fieldset .form-group:first-of-type {
  margin-top: 0;
}
html.mgh .login-wrap fieldset .form-group.myaccess {
  background: #EEF9F7;
  border-radius: 6px;
  padding: 30px;
  box-sizing: border-box;
}
html.mgh .login-wrap fieldset .form-group.myaccess .no-account {
  margin-bottom: 0.5rem;
}
html.mgh .login-wrap fieldset .form-group.myaccess a {
  text-decoration: none;
  color: #440B7F;
  font-weight: 600;
}
html.mgh .login-wrap fieldset .form-group.forgot-pw_wrap {
  display: flex;
  justify-content: space-evenly;
}
html.mgh .login-wrap fieldset .form-group.forgot-pw_wrap a {
  font-size: 15px;
  text-decoration: none;
  color: #440B7F;
  font-weight: 600;
}
html.mgh .login-wrap fieldset .form-group.forgot-pw_wrap a img {
  margin-left: 0.125rem;
}
html.mgh .login-wrap fieldset .form-group .sign-in-text {
  font-weight: 700;
  font-size: 22px;
  line-height: 127%;
  color: #131519;
  letter-spacing: 0.35px;
}
html.mgh .login-wrap fieldset .form-group input {
  border: none;
}
html.mgh .login-wrap fieldset .form-group input[placeholder] {
  font-size: 17px;
  color: #676D7A;
}
html.mgh .login-wrap fieldset .form-group .message {
  font-weight: 400;
  font-size: 17px;
  color: #676D7A;
  margin-top: 0.5rem;
}
html.mgh .login-wrap fieldset .form-group .form-control {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 0px 16px;
  max-width: 335px;
  max-height: 44px;
  background: #F5F7FA;
  border-radius: 6px;
}
html.mgh .login-wrap fieldset .form-group .btn-primary {
  background: #440B7F;
  border-color: #440B7F;
  border-radius: 96px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 335px;
  height: 48px;
  align-items: center;
}
html.mgh .login-wrap fieldset .form-group .btn-primary:disabled {
  background: #CACED9;
  border-color: #CACED9;
  pointer-events: none;
}
html.mgh .login-wrap fieldset .form-group.myaccess {
  text-align: center;
}

.tools-portal-content_wrap {
  max-width: 1300px;
  margin: 3rem auto 125px auto;
  padding: 0 1rem;
}

.tp-content-header {
  text-align: center;
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 0.875rem;
  color: #505355;
}

.tp-content-row {
  display: flex;
  justify-content: center;
}

.tp-content {
  width: 100%;
  max-width: 330px;
  margin: 0 1rem;
  border-radius: 4px;
}
.tp-content a {
  padding: 2rem;
  border: 3px solid #D8E5EE;
  border-top: none;
  display: block;
  box-shadow: 0px 7px 9px #042E5C19;
  height: calc(100% - 4rem);
  text-decoration: none;
}
.tp-content.sitemanager {
  border-top: 8px solid #F3C413;
}
.tp-content.sitemanager:hover a {
  border-color: #F3C413;
}
.tp-content.sitebuilder {
  border-top: 8px solid #E1382C;
}
.tp-content.sitebuilder:hover a {
  border-color: #E1382C;
}
.tp-content.productbuilder {
  border-top: 8px solid #47B5A0;
}
.tp-content.productbuilder:hover a {
  border-color: #47B5A0;
}
.tp-content.zipline {
  border-top: 8px solid #0483C0;
}
.tp-content.zipline:hover a {
  border-color: #0483C0;
}
.tp-content.composer {
  border-top: 8px solid #042E5C;
}
.tp-content.composer:hover a {
  border-color: #042E5C;
}
.tp-content.analytics {
  border-top: 8px solid #8C9296;
}
.tp-content.analytics:hover a {
  border-color: #8C9296;
}
.tp-content span {
  display: block;
}
.tp-content span.logo-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.tp-content span.logo-wrap i {
  font-size: 1.25rem;
  color: #B8BFBF;
}

.tp-content-text {
  color: #505355;
}
.tp-content-text > span {
  margin-top: 1rem;
  display: flex;
  align-items: end;
  font-size: 0.875rem;
}
.tp-content-text > span i {
  margin-right: 1rem;
  font-size: 1.25rem;
  color: #B8BFBF;
}
.tp-content-text > span:first-of-type {
  margin-top: 0;
}

.cmr-content_wrap {
  margin-top: 4rem;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}

body {
  /* Margin bottom by footer height */
  margin: 0;
  margin-bottom: 60px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: calc(100% - 2rem);
  left: 0;
  white-space: nowrap;
  line-height: 60px;
  /* Vertically center the text there */
  background: #141B24;
  padding: 0 1rem;
}
.footer * {
  color: #8C9296;
}

.login.mgh .footer {
  display: none;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(56, 54, 54, 0.5);
}
.modal .modal-content {
  background-color: #feffff;
  margin: 15% auto;
  padding: 2rem 2.5rem;
  width: 30%;
}
.modal .close {
  color: #8C9296;
  float: right;
  font-size: 3rem;
}
.modal .close:hover,
.modal .close:focus {
  color: #1a1a1a;
  cursor: pointer;
}
.modal a {
  color: #1b6ec2;
  font-size: 1.2rem;
  font-weight: bold;
}
.modal ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.modal li {
  margin-bottom: 0.5rem;
}