﻿.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: $black-opaque;

    .modal-content {
        background-color: $white;
        margin: 15% auto;
        padding: 2rem 2.5rem;
        width: 30%;
    }

    .close {
        color: $gray;
        float: right;
        font-size: 3rem;
    }

    .close:hover,
    .close:focus {
        color: $black;
        cursor: pointer;
    }

    a {
        color: $site-primary;
        font-size: 1.2rem;
        font-weight: bold;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    li {
        margin-bottom: .5rem;
    }
}
