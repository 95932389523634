﻿html.login {
    background: $gradient-bg;

    .login-wrap {
        .sc-logo {
            text-align: center;

            img {
                width: 275px;
            }
        }

        .login-text {
            font-size: 1.5rem;
            color: $white;
            text-align: center;
            margin-bottom: $space-150;
        }

        &.oauth {
            &.score {
                margin: 0 auto;
                padding: 2rem 0;
                height: auto;
                background-image: linear-gradient(to top,#000a39,#0d1f74 95%);

                .sc-logo img {
                    width: auto;
                    max-width: 300px;
                    max-height: 50px;
                }

                fieldset {
                    padding: 2rem;
                    width: auto;
                    min-width: calc(300px - 2 * 2rem);
                    max-width: 450px;
                }
            }
        }
    }
}

.alert.alert-danger {
    border: 1px solid $red;
    padding: 1rem;
    margin-bottom: $space;

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
}

fieldset {
    background: $white;
    border: 1px solid #D8E5EE;
    width: 450px;
    margin: 0 auto;
    padding: $space-200;
    border-radius: 4px;

    .form-group {
        .instructions {
            margin: 0 0 $space 0;
        }

        label {
            display: block;
            font-weight: 600;
            margin-bottom: $space-50;
        }

        input[type="text"], input[type="password"], input[type="email"] {
            width: 100%;
            height: 40px;
            border: 1px solid $border-gray;
            border-radius: 2px;
            margin-bottom: $space-150;
            padding: 0 $space-50;
            box-sizing: border-box;
        }
    }

    .form-group.checkbox-row {
        margin-bottom: $space-150;
        display: flex;
        align-items: center;

        label {
            font-weight: 600;
            margin: 0 $space-50 0 0;
        }

        input[type="checkbox"] {
            margin: $space-25 0;
        }
    }

    .form-group.btn-row {
        .btn {
            margin-left: $space-50;
        }

        .btn:first-of-type {
            margin-left: 0;
        }
    }
}

.btn {
    color: $white;
    background-color: $site-primary;
    border: 1px solid $site-primary;
    border-radius: 4px;
    font-size: 18px;
    padding: $space-50 $space;
    cursor: pointer;
}



html:not(.mgh) {
    .forgot-password-link {
        font-weight: 600;
        margin-left: $space-150;
    }

    .login-wrap {
        height: 420px;
        position: absolute;
        top: 0;
        bottom: 60px;
        left: 0;
        right: 0;
        margin: auto;
    }
}

.return-to-sign-in {
    margin-left: 0.5rem;
    text-decoration: none;
}

html.mgh {
    * {
        font-family: 'Roboto', sans-serif;
    }

    background: #440B7F;
    font-style: normal;
    font-size: 17px;
    line-height: 22px;
    // Leaving this for now since MGH provided this but it's not providing the desired effect.
    //mix-blend-mode: normal;
    //background-image: linear-gradient(218.19deg, #16D9B7 14.18%, rgba(131, 136, 226, 0.1) 50.49%);

    .login-wrap {
        height: 600px;
        margin: 10% auto;

        .login-text {
            font-family: 'Roboto Serif', serif;
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 1;
            text-align: center;
            letter-spacing: -0.408px;
            margin-bottom: 0;

            @media (max-width: 420px) {
                text-align: left;
                margin-left: $space-150;
            }
        }

        .login-text-sub {
            font-weight: 600;
            text-align: center;
            letter-spacing: -0.408px;
            color: $white;
            margin: .25rem 0 $space-150 0;
            line-height: 1;

            @media (max-width: 420px) {
                text-align: left;
                margin-left: $space-150;
            }
        }

        fieldset {
            @media (min-width: 421px) {
                border-radius: 36px;
                max-width: 428px;
                max-height: 617px;
            }

            @media (max-width: 420px) {
                border-top-right-radius: 36px 36px;
            }

            width: 100%;
            padding: 2rem 0;
            box-sizing: border-box;

            .form-group {
                margin: 0 auto;
                position: relative;
                max-width: 335px;
                margin-top: 24px;

                &:first-of-type {
                    margin-top: 0;
                }

                &.myaccess {
                    background: #EEF9F7;
                    border-radius: 6px;
                    padding: 30px;
                    box-sizing: border-box;

                    .no-account {
                        margin-bottom: .5rem;
                    }

                    a {
                        text-decoration: none;
                        color: #440B7F;
                        font-weight: 600;
                    }
                }

                &.forgot-pw_wrap {
                    display: flex;
                    justify-content: space-evenly;

                    a {
                        font-size: 15px;
                        text-decoration: none;
                        color: #440B7F;
                        font-weight: 600;

                        img {
                            margin-left: .125rem;
                        }
                    }
                }

                .sign-in-text {
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 127%;
                    color: #131519;
                    letter-spacing: 0.35px;
                }

                input {
                    border: none;

                    &[placeholder] {
                        font-size: 17px;
                        color: #676D7A;
                    }
                }

                .message {
                    font-weight: 400;
                    font-size: 17px;
                    color: #676D7A;
                    margin-top: .5rem;
                }

                .form-control {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0px 0px 0px 16px;
                    max-width: 335px;
                    max-height: 44px;
                    background: #F5F7FA;
                    border-radius: 6px;
                }

                .btn-primary {
                    background: #440B7F;
                    border-color: #440B7F;
                    border-radius: 96px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    width: 335px;
                    height: 48px;
                    align-items: center;
                }

                .btn-primary:disabled {
                    background: #CACED9;
                    border-color: #CACED9;
                    pointer-events: none;
                }

                &.myaccess {
                    text-align: center;
                }
            }
        }
    }
}
