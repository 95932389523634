﻿html * {
    font-family: 'Source Sans Pro', sans-serif;
}

.tools-portal_header {
    display: flex;
    align-items: center;
    background: $gradient-bg;
    height: 68px;
    padding: 0 2rem;
}

.tools-portal-nav {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
        margin-left: $space-200;

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        > a {
            color: $white;
        }
    }
}
.user-info {
    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    > a {
        color: $white;
    }
}
li.with-dropdown {
    position: relative;
}
.dropdown-trigger {
    white-space: nowrap;
}
.dropdown-trigger:after {
    font-family: 'feather' !important;
    content: "\e92e";
    margin-left: $space-50;
    display: inline-block;
}
.dropdown {
    display: none;
    list-style-type: none;
    position: absolute;
    top: 44px;
    border: 1px solid $border-gray;
    padding: $space;
    background: $white;
    width: 200px;
    box-shadow: 0 7px 9px #042e5c19;

    li {
        margin-left: 0;

        a {
            color: $dark-gray-text;
        }
    }
}
.user-info {
    position: relative;

    .dropdown {
        right: 0;
        top: 28px;
    }

    .dropdown-trigger {
        display: flex;
        align-items: center;
    }
    .dropdown-trigger:before {
        font-family: 'feather' !important;
        content: "\ea00";
        margin-right: $space-50;
        display: inline-block;
    }
}
